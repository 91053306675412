import { memo } from 'react'
import OrderSummaryItem from './OrderSummaryItem'
import OrderSummaryItemLine from './OrderSummaryItemLine'
import { useHeadlessCheckoutContext } from '@/context/HeadlessCheckoutContext'

const OrderSummaryBreakdown = ({ readOnly }) => {
  const { data } = useHeadlessCheckoutContext()

  const discounts = []
  data.cart.discounts.forEach((d) => {
    const existing = discounts.find((ex) => ex.code === d.code)
    if (existing)
      existing.totalDiscounted += parseFloat(d.discountedAmount.amount)
    else
      discounts.push({
        ...d,
        totalDiscounted: parseFloat(d.discountedAmount.amount),
      })
  })

  const hasOnlyIntroBox = data.cart.lineItems.every(
    (li) => li.merchandise?.product?.handle === 'sitka-seafood-intro-box',
  )

  const hasFreeShippingDiscount = data.cart?.discountCodes.some(
    (d) => d.applicable && d.code === 'Ship with Next Order',
  )

  let shippingCost
  if (!hasFreeShippingDiscount) {
    const shippingCostArray = data.cart?.deliveryGroups.nodes.map((group) =>
      parseFloat(group.selectedDeliveryOption?.estimatedCost.amount),
    )
    shippingCost =
      shippingCostArray.length > 0
        ? shippingCostArray.reduce((total, cost) => (total += cost), 0)
        : NaN
  } else {
    shippingCost = 0
  }

  const isExpeditedShipping = data.cart?.deliveryGroups.nodes[0]?.selectedDeliveryOption.code === 'Expedited Shipping'

  return (
    <MemoizedOrderSummaryBreakdown
      lineItemsTotal={parseFloat(data.cart.cost.subtotalAmount.amount)}
      shippingTotal={shippingCost}
      total={parseFloat(data.cart.cost.totalAmount.amount)}
      taxTotal={parseFloat(data.cart.cost.totalTaxAmount?.amount)}
      discounts={discounts}
      readOnly={readOnly}
      hasOnlyIntroBox={hasOnlyIntroBox}
      isExpeditedShipping={isExpeditedShipping}
    />
  )
}

const MemoizedOrderSummaryBreakdown = memo(
  ({
    lineItemsTotal,
    shippingTotal,
    taxTotal,
    total,
    discounts,
    readOnly,
    hasOnlyIntroBox,
    isExpeditedShipping,
  }) => {
    const discountLines = discounts.map((discount) => (
      <OrderSummaryItemLine
        key={`${discount.code || discount.title}`}
        description={`${discount.code || discount.title}`}
        amount={`${discount.totalDiscounted}`}
        readOnly={readOnly}
      />
    ))

    const shippingThreshold = 125
    
    const isShippingCostCalculated = !isNaN(shippingTotal);

    const hasFreeShippingDiscount =
    ( isShippingCostCalculated  && shippingTotal === 0) ||
      lineItemsTotal > shippingThreshold ||
      hasOnlyIntroBox

    return (
      <div className="order-summary__breakdown">
        <div className="breakdown__section checkout__row">
          <OrderSummaryItem title="Subtotal" amount={lineItemsTotal} />
          {discountLines && discountLines.length > 0 && (
            <OrderSummaryItem title="Discounts" lines={discountLines} />
          )}
          <OrderSummaryItem
            title={
              isShippingCostCalculated && lineItemsTotal < shippingThreshold && !hasOnlyIntroBox
                ? `Shipping (earn free standard shipping on orders over $${shippingThreshold})`
                : 'Shipping'
            }
            amount={shippingTotal}
            emptyAmountDisplay={
              !isShippingCostCalculated && lineItemsTotal < shippingThreshold && !hasOnlyIntroBox
                ? `free shipping on orders over $${shippingThreshold}`
                : (<p className="free-shipping-amount">$0.00</p>)
            }
            isShippingLine={true}
            cartTotal={lineItemsTotal}
            shippingThreshold={shippingThreshold}
            hasFreeShippingDiscount={hasFreeShippingDiscount}
            isExpeditedShipping={isExpeditedShipping}
          />
          {taxTotal > 0 && <OrderSummaryItem title="Tax" amount={taxTotal} />}
        </div>
        <div className="breakdown__section checkout__row checkout__total-row breakdown__section--total">
          <OrderSummaryItem title={'TOTAL'} amount={total} isTotal={true} />
        </div>
      </div>
    )
  },
)

export default OrderSummaryBreakdown
