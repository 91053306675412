import { memo, useState, useEffect } from 'react'
import { ShippingLineList } from './components'
import { useHeadlessCheckoutContext } from '@/context/HeadlessCheckoutContext'
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect'
import { getIdFromSourceEntryId } from '@/utils/sourceEntryId'
import { ShippingAddress } from '../ShippingAddress'
import { useCustomerContext } from '@/context/CustomerContext'

const ShippingLines = ({ applicationLoading }) => {
  const {
    data: { cart },
    requiresShipping,
    selectShippingLine,
    getShipOptions,
  } = useHeadlessCheckoutContext()
  const { customer } = useCustomerContext()
  const isGiftOrder = cart?.properties.is_gift_order == 'true'

  if (!requiresShipping) return undefined
  if (!customer) return undefined

  return (
    <MemoizedShippingLines
      selectedShipWeekPreference={cart.properties.ship_week_preference}
      appLoading={applicationLoading}
      lineItems={cart.lineItems}
      requiresShipping={requiresShipping}
      selectShippingLine={selectShippingLine}
      getShipOptions={getShipOptions}
      address={cart.shippingAddress}
      shippingLines={cart?.shippingLines || []}
      isGiftOrder={isGiftOrder}
    />
  )
}

const MemoizedShippingLines = memo(
  ({
    address,
    appLoading,
    lineItems,
    selectedShipWeekPreference,
    requiresShipping,
    selectShippingLine,
    getShipOptions,
    shippingLines,
    isGiftOrder,
  }) => {
    const [shipWeekPreference, setShipWeekPreference] = useState(
      selectedShipWeekPreference || '',
    )
    const [errors, setErrors] = useState(null)
    const [loading, setLoading] = useState(false)
    const [displayedShippingLines, setDisplayedShippingLines] = useState([])
    const [selectedShippingLine, setSelectedShippingLine] = useState(null)
    const zip = address?.zip

    const productIds = lineItems.map((li) => li.variant.product.id)
    const expeditedProductIds = Array.from(
      JSON.parse(process.env.NEXT_PUBLIC_AUTOMATICALLY_EXPEDITED_PRODUCTS),
    )
    const hasExpeditedProduct = productIds.some((id) =>
      expeditedProductIds?.includes(getIdFromSourceEntryId(id)),
    )
    const hasSubscriptionProduct = lineItems.some((li) =>
      li.variant.product.tags?.includes('Subscription Box'),
    )

    async function updateShippingOptions({
      line,
      shipWeek,
      estimatedDeliveryDateDisplay,
    }) {
      setLoading(true)
      try {
        setSelectedShippingLine(line)
        setShipWeekPreference(shipWeek)
        await selectShippingLine({
          shippingLine: line,
          shipWeek,
          estimatedDeliveryDateDisplay,
        })
        setErrors(null)
      } catch (e) {
        console.error(e)
        setErrors(e.body?.errors)
        // If there was an error, reset the selected shipping line to the previous selected shipping line.
        setSelectedShippingLine(selectedShippingLine)
      }
      setLoading(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    useDeepCompareEffectNoCheck(() => {
      async function updateDisplayedShipLines() {
        setLoading(true)
        try {
          const shipOptions = await getShipOptions(zip)

          const lines = []
          if (shipOptions?.standard) {
            const shippingLine = shippingLines.find(
              (line) =>
                line.title.toLowerCase()?.includes('standard') ||
                line.title.toLowerCase()?.includes('ship with next'),
            )
            if (!isNaN(shippingLine?.cost)) {
              lines.push({
                options: [...shipOptions.standard],
                title: 'Standard Shipping',
                price: shippingLine?.cost,
                // removing this for now because it causes the next use effect to trigger too often
                // selectedDeliveryOptionInput: shippingLine?.selectedDeliveryOptionInput || []
              })
            }
          }
          if (shipOptions?.bundled) {
            const shippingLine = shippingLines.find(
              (line) =>
                line.title.toLowerCase()?.includes('standard') ||
                line.title.toLowerCase()?.includes('ship with next'),
            )
            if (!isNaN(shippingLine?.cost)) {
              lines.push({
                ...shipOptions.bundled,
                title: 'Ship with Next Order',
                price: 0,
                // removing this for now because it causes the next use effect to trigger too often
                // selectedDeliveryOptionInput: shippingLine?.selectedDeliveryOptionInput || []
              })
            }
          }
          if (shipOptions?.expedited) {
            const shippingLine = shippingLines.find((line) =>
              line.title.toLowerCase()?.includes('expedited'),
            )
            if (!isNaN(shippingLine?.cost)) {
              lines.push({
                ...shipOptions.expedited,
                title: 'Expedited Shipping',
                price: shippingLine?.cost,
                // removing this for now because it causes the next use effect to trigger too often
                // selectedDeliveryOptionInput: shippingLine?.selectedDeliveryOptionInput || []
              })
            }
          }

          setDisplayedShippingLines(lines)
        } finally {
          setLoading(false)
        }
      }

      if ((!zip || zip.length !== 5) && displayedShippingLines.length > 0) {
        setDisplayedShippingLines([])
      } else {
        updateDisplayedShipLines()
      }
    }, [zip, shippingLines])

    useDeepCompareEffectNoCheck(() => {
      if (displayedShippingLines.length > 0) {
        if (
          !displayedShippingLines.find(
            (line) => line.title === selectedShippingLine?.title,
          )
        ) {
          // Automatically select certain shipping lines when there is not one selected
          const standard = displayedShippingLines.find((line) =>
            line.title?.includes('Standard Shipping'),
          )
          const expedited = displayedShippingLines.find((line) =>
            line.title?.includes('Expedited'),
          )
          let line = displayedShippingLines[0]
          if (standard) line = standard
          if (expedited && hasExpeditedProduct) line = expedited

          updateShippingOptions({ line })
        }
      }
    }, [displayedShippingLines])

    return (
      requiresShipping && (
        <div
          data-testid="shipping-lines-component"
          className="order-shipping-method"
        >
          <div
            className={`checkout__header checkout__header--border-on-closed checkout__row checkout__header--open`}
          >
            <h3>{isGiftOrder ? 'Recipient Estimated Delivery Date' : 'Your Estimated Delivery Date'}</h3>
          </div>
          <>
            {errors && <p type="alert">{errors[0].message}</p>}
            <div className="field-set__content">
              <ShippingAddress />
            </div>
            <ShippingLineList
              loading={appLoading}
              shippingLines={displayedShippingLines}
              selectedShippingLine={selectedShippingLine}
              onChange={updateShippingOptions}
              disabled={loading}
              selectedStandardShipWeek={shipWeekPreference}
            />
          </>
        </div>
      )
    )
  },
)

export default ShippingLines
