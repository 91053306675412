import { memo } from 'react';
import { Address } from '@/components/HeadlessCheckout/Address';
import { useHeadlessCheckoutContext } from '@/context/HeadlessCheckoutContext';
import Typography from '@mui/material/Typography';

const ShippingAddress = () => {
  const { data, updateShippingAddress, isAddressValidating } = useHeadlessCheckoutContext()
  const shippingAddress = data?.cart?.shippingAddress
  const isGiftOrder = data?.cart?.properties.is_gift_order == 'true'

  return (
    <MemoizedShippingAddress
      shippingAddress={shippingAddress}
      shippingLines={data.availableShippingRates}
      updateShippingAddress={updateShippingAddress}
      isGiftOrder={isGiftOrder}
      isAddressValidating={isAddressValidating}
    />
  );
};

const MemoizedShippingAddress = memo(({
  shippingAddress,
  updateShippingAddress,
  isGiftOrder,
  isAddressValidating
}) => {
  const address = shippingAddress?.formatted[0]

  const handleCustomAddressChange = async (newAddress) => {
    if (newAddress && newAddress.city && newAddress.address1) {
      newAddress.country = 'US'
    }
    console.debug('filled in new address: ', newAddress);
    await updateShippingAddress(newAddress);
  }

  return (
    <div className="order-address">
      <Address
        address={address || ''}
        onChange={(value) => handleCustomAddressChange(value)}
        isGiftOrder={isGiftOrder}
      />
      <Typography variant="body2" sx={{ fontFamily: 'inherit' }} ml={2} mb={2}>
        {isAddressValidating ? 'Validating...' : ''}
      </Typography>
    </div>
  );
});

export default ShippingAddress